body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: #ffffff;
    color: inherit;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-sizing: border-box;
    box-shadow: none;
}

button:hover,
button:focus {
    background: #eeeeee;
    outline: none;
    border-color: rgba(0,0,0,0);
}
